

.App {
  text-align: center;

}

.footer {

  left: 0;
  bottom: 0;

  min-height: 200px;
  background-color: #383040;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: auto;
  padding: 20px;
}

.innerBody {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  color: white;
  padding: 40px;
  padding-top: 100px;

}

@media only screen and (min-width: 1200px) {
  .innerBody {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.dark {
  background-color: #282c34;
  color: white;
}

.light {
  background-color: #d3c9d3;
  color: black;
}

.navStyle {

  color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.darkText {
  color: white !important;
} 

.lightText {
  color: black !important;
}

.center 
{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1.4vmin);
  color: white;
  align-items: center;
  text-align: center;
  margin: auto

}
.g-link {
  color: #b7b7b7;
  text-decoration: none;
}

.MuiButton-containedPrimary 
{
  background-color:  #6a75dc !important;
  color: white !important;
}


.hover {
  padding: 5px;
}
.hover:hover {
  background-color: #00000022 !important;
  
  padding: 5px;
  border-radius: 10%;
}

.customIcon:hover {
  width: 120px;
  margin: auto;
}

.linkIcon
{
  height: 48px;
  display: flex;
  align-items: center;
}

.loading 
{
  opacity: 20%;
}

.normal 
{
  opacity: 100%;
}

.blogMarkdown {
  text-align: left;
}